import React from 'react';
import ReactCalendar from 'react-calendar';
import './style.css';

const Calendar = function (props: any) {
    return (
        <ReactCalendar 
            className="calendar-wrapper" {...props} 
            locale="ro-Ro"
        />
    )
}

export default Calendar;