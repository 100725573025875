import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Label, LabelGroup } from 'semantic-ui-react';
import { gqlClient } from '../../queries/base';
import { MUTATION_CREATE_CLIENT } from '../../queries/Client';
import { isEmail } from '../../utils';

const SEARCH_DEBOUNCE_TIMEOUT = 500;

export interface IClientRegistrationData {
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
};

interface  IClientRegisterProps {
    email: string | null;
    reservation: {
      shouldCreate: boolean;
      handler: (clientId: string | null) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    }
}

function handleCreateAccount(
  createUserAccount: (c?: any) => Promise<any>, 
  setAccountCreatedButNoConfirmation: (v: boolean) => void
) {
  return async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const res = await createUserAccount();
    setAccountCreatedButNoConfirmation(true);
    return res;
  };
}

function handleCreateNewAccountAndReservation(
  createUserAccount: (c?: any) => Promise<any>, 
  setAccountCreatedButNoConfirmation: (v: boolean) => void,
  createReservationHandler: (clientId: string | null) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
) {
  return async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const createAccountRes = await handleCreateAccount(createUserAccount, setAccountCreatedButNoConfirmation)(e);
    await createReservationHandler(createAccountRes.data.clientSignup.id)(e);
  };
}

// function handleSendReservationButton(
//     reservationTypes: IReservationType[], 
//     clientEmailAddress: string,
//     dayMonthYear: Date,
//     startHour: number,
//     startMinute: number,
//     stylistId: string
//   ) {
  
//     const createReservationData = {
//       clientId: "",
//       dayMonthYear: dayMonthYear,
//       reservationTypeIds: reservationTypes.map(r => r.id),
//       startHour,
//       startMinute,
//       stylistId
//     }
//     return () => {
      
//     };
// }

const ClientRegister = (props: IClientRegisterProps) => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [phonenumber, setPhonenumber] = useState<string | null>(null);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [accountCreatedButNoConfirmation, setAccountCreatedButNoConfirmation] = useState<boolean | null>(null);

  useEffect(() => {
    const firstnameValid = firstName && (firstName.length > 1 && firstName.length < 35);
    const lastnameValid = lastName && (lastName.length > 1 && lastName.length < 35);
    const phonenumberValid = phonenumber && (phonenumber.length > 8 && phonenumber.length < 15);
    setIsValidForm(Boolean(firstnameValid && lastnameValid && phonenumberValid && isEmail(props.email)));
  }, [firstName, lastName, phonenumber]);

  const [createUserAccount, { data: createUserAccountRes, loading, error }] = useMutation(MUTATION_CREATE_CLIENT, {
    variables: {
      data: {
        email: props.email,
        firstname: firstName,
        lastname: lastName,
        phonenumber
      },
    },
    client: gqlClient,
    fetchPolicy: 'network-only'
  }
  );

  
  return (
    <Form attached>
      <Form.Field>
        Prenume:
        <Input
          onChange={(e) => { e.preventDefault(); setFirstName(e.target.value)}}
        />
        Nume:
        <Input
          onChange={(e) => { e.preventDefault(); setLastName(e.target.value)}}
        />
        Numarul de telefon:
        <Input
          type="tel"
          onChange={(e) => { 
            e.preventDefault(); 
            let cleanedPhoneNumber = e.target.value.trim();
            cleanedPhoneNumber = cleanedPhoneNumber.replace('(', '');
            cleanedPhoneNumber = cleanedPhoneNumber.replace(')', '');
            cleanedPhoneNumber = cleanedPhoneNumber.replace('+', '00');
            cleanedPhoneNumber = cleanedPhoneNumber.replaceAll(' ', '');
            setPhonenumber(cleanedPhoneNumber);
          }}
        />
      </Form.Field>
      {
        !accountCreatedButNoConfirmation
        ? <Button 
          disabled={!isValidForm}
          onClick={
            props.reservation.shouldCreate 
            ? handleCreateNewAccountAndReservation(createUserAccount, setAccountCreatedButNoConfirmation, props.reservation.handler)
            : handleCreateAccount(createUserAccount, setAccountCreatedButNoConfirmation)
          }
        >
          {props.reservation.shouldCreate ? "Creeaza cont si rezervare" : "Creeaza cont" }
        </Button>
        : <LabelGroup>
          <Label size='large'>Multumim pentru inregistrare. Tocmai ce ti-am trimis un email de verificare. </Label>
          
          {props.reservation.shouldCreate 
          ? <>
              <Label size='large' color='red'>Rezervarea ta nu este activa!</Label>
              <Label size='large' color='red'>Apasa pe linkul din email pentru a iti activa contul si rezervarea</Label>
            </>
          : <Label size='large' color='green'>Apasa pe linkul din email pentru a iti activa contul si intoarce-te pe aceasta pagina ca sa creezi o rezervare.</Label>
          }
          <Label size='large'>In caz ca nu gasesti emailul, verifica si in Spam.</Label>
        </LabelGroup>

      }
    </Form>
  );
};

export default ClientRegister;

