import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Header, Segment, Select } from 'semantic-ui-react';
import { gqlClient } from '../../queries/base';
import { QUERY_GET_ALL_STYLISTS } from '../../queries/Stylist';

export interface IStylist {
    id: string;
    firstname: string;
    lastname: string;
  }

interface IStylistPickerProps {
    onSelectedStylistId: (stylistId: string) => void;
}

function allStylistsToDropdownOptions(data: any): {key: string, value: string, text: string}[] {
    const allStylistsData = data.getAllStylists;
    return allStylistsData.map((sd: IStylist, ksd: string) => ({
      key: ksd,
      value: sd.id,
      text: `${sd.firstname} ${sd.lastname[0]}.`
    }));
  }
  
const StylistPicker = (props: IStylistPickerProps) => {
    const [selectedStylistId, setSelectedStylistId] = useState<string | null>(null);
    const { loading: reservationBlocksLoading, data: allStylistsData, error } = useQuery(QUERY_GET_ALL_STYLISTS, {
        client: gqlClient
    });
    useEffect(() => {
        if (allStylistsData && allStylistsData.getAllStylists && allStylistsData.getAllStylists[0] && !selectedStylistId) {
            props.onSelectedStylistId(allStylistsData.getAllStylists[0].id as string);
        }
      }, [allStylistsData])
      
    return (
        <Segment attached>
            <Header as='h3' attached='top'>
                Alege frizerul
            </Header>
        { allStylistsData && 
          <Select
            fluid
            options={allStylistsToDropdownOptions(allStylistsData)} 
            placeholder="Selecteaza frizerul" 
            onChange={(e, data) => {e.preventDefault(); props.onSelectedStylistId(data.value as string);}}
            defaultValue={allStylistsData && allStylistsData.length && allStylistsData.getAllStylists[0].id}
          /> 
        }
      </Segment> 
    );
};

export default StylistPicker;