import React, { useEffect, useState } from 'react';
import { Button, Container, Header, Message, Segment } from 'semantic-ui-react';
import PageHeader from '../../components/header';

import { Routes, Route, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MUTATION_DELETE_RESERVATION_FOR_CLIENT } from '../../queries/Reservations';
import { gqlClient } from '../../queries/base';
import { MUTATION_CONFIRM_CLIENT_SIGNUP } from '../../queries/Client';
import { fromJSDateToReadableString, fromJSDateToUTCDateTime } from '../../utils';

function ConfirmAccountPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const registrationNonce = searchParams.get("registrationNonce");
  const [clientAlreadyActivated, setClientAlreadyActivated] = useState<boolean | null>(false);
  const [cancelledReservations, setCancelledReservations] = useState<{ startHrMinDate: Date }[]>([]);
  const [activatedReservations, setActivatedReservations] = useState<{ startHrMinDate: Date }[]>([]);
  const [confirmClientSignup, { loading, error }] = useMutation(MUTATION_CONFIRM_CLIENT_SIGNUP, {
    variables: {
      data: {
        email,
        registrationNonce
      },
    },
    client: gqlClient,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    async function doSmth() {
      const res = await confirmClientSignup();
      setActivatedReservations(res.data.confirmClientSignup.activatedReservations.map((res: any) => ({ startHrMinDate: new Date(res.startHrMinDate) })));
      setCancelledReservations(res.data.confirmClientSignup.cancelledReservations.map((res: any) => ({ startHrMinDate: new Date(res.startHrMinDate) })));
      setActivationSuccessful(res.data.confirmClientSignup.success);
      setClientAlreadyActivated(res.data.confirmClientSignup.clientWasPreviouslyActive);
      // console.log('youpiee', email, registrationNonce);
    }
    doSmth();
  }, []);

  const [activationSuccessful, setActivationSuccessful] = useState<boolean>(false);
  return (
    <Container>
    <PageHeader />
    <Segment>{
      activationSuccessful ?
        <>
        <Message>
          <Header>✅ Cont creat cu succes</Header>
        </Message>{
            cancelledReservations.length !== 0 
            && <Message color='red'>
              <p>
                <div>
                  ⚠️ Din pacate a trebuit sa iti anulam rezervarea ta:
                  <ul>
                    {cancelledReservations.map((reservation) =>  
                    <li>{fromJSDateToReadableString(reservation.startHrMinDate)}</li>)}
                </ul>
                </div>
              </p>
            </Message>
        }
        {activatedReservations.length === 0 
        ? <Message>
          <p>
            {
              cancelledReservations.length === 0 
              ? "Haide acum pe pagina de rezervari, ca sa-ti faci prima rezervare."
              : "Haide acum pe pagina de rezervari, ca sa iti faci alta."
            }
          </p>
        </Message>
        :
        <Message color='green'>
          <p>
            <div>
              🗓️ Am confirmat rezervarea ta:
              <ul>
              {
              activatedReservations.map((reservation) => {
                return <li>{fromJSDateToReadableString(reservation.startHrMinDate)}</li>
              })}
            </ul>
            <Message>Ti-am trimis si un mail cu aceasta rezervare si un link de anulare, in caz ca te-ai razgandit. Daca nu il gasesti, asigura-te ca ai verificat si in Spam.</Message>
          </div>
          </p>
        </Message>
        }
        <Button onClick={() => navigate("/")}>Catre pagina de rezervari</Button>  
        </>
      : loading 
        ? "Se incarca..." 
        : error 
          ? "Ne pare rau, dar a aparut erorare. Te rugam sa incerci din nou sau sa ne contactezi la telefon." 
          : clientAlreadyActivated
            ? <>
              <Message>
              🤷‍♂️ Contul tau era deja activat. Haide pe pagina de rezervari ca sa te programezi.
              </Message>
              <Button onClick={() => navigate("/")}>Catre pagina de rezervari</Button>  
            </>
            : ""
    }</Segment>
    </Container>
  )
}

export default ConfirmAccountPage;