import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useEffect, useState } from "react";
import AuthedGqlSingletonFactory, { gqlClient } from "../../../queries/base";
import { Button, Container, Form, Grid, Header, Icon, Label, List, Modal, Segment, TextArea } from 'semantic-ui-react';
import { StylistAuth } from '../../../services/stylistAuth';
import { useNavigate } from 'react-router';
import { IReservationBlock, QUERY_GET_RESERVATION_BLOCKS_FOR_STAFF } from '../../../queries/ReservationBlocks';
import { DateTime } from 'luxon';
import StylistPicker from '../../../components/stylistPicker/stylistPicker';
import Calendar from '../../../components/calendar';
import { fromJSDateToUTCDateTime } from '../../../utils';
import { MUTATION_DELETE_RESERVATION_FOR_STAFF } from '../../../queries/Reservations';
import { standardiseHoursFormat } from '../../../queries/adapters';
import { MUTATION_CREATE_CLIENT_RESERVATION_COMMENTS, MUTATION_DELETE_CLIENT_RESERVATION_COMMENTS, QUERY_GET_CLIENT_RESERVATION_COMMENTS } from '../../../queries/ClientReservationComments';

interface IClient {
  id: string
  firstname: string;
  lastname: string;
  phonenumber: string;
  email: string;
}

interface IDayTimeBlockWithStaffDetails extends IReservationBlock {
  client: IClient;
  stylist: IStylist;
  reservationId: string | null;
  reservation?: {
    types: {
      reservationTypeId: string;
      reservationId: string;
      reservationType: {
        name: string;
        price: number;
      }
    }[];
  }
};

interface IStylist {
  id: string;
  firstname: string;
  lastname: string;
}

function allStylistsToDropdownOptions(data: any): {key: string, value: string, text: string}[] {
  const allStylistsData = data.getAllStylists;
  return allStylistsData.map((sd: IStylist, ksd: string) => ({
    key: ksd,
    value: sd.id,
    text: `${sd.firstname} ${sd.lastname[0]}.`
  }));
}

function StylistViewReservationsPage() {
  const [commentSaved, setCommentSaved] = useState<boolean|null>(null);
  const [selectedDay, setSelectedDay] = useState<Date>(DateTime.fromJSDate(new Date()).startOf('day').toJSDate());
  const [selectedStylistId, setSelectedStylistId] = useState<string|null>(null);
  const [deleteReservationId, setDeleteReservationId] = useState<string|null>(null);
  const [clientReservationNoteText, setClientReservationNoteText] = useState<string|null>(null);
  const [mergedReservationsData, setMergedReservationsData] = useState<any[]|null>(null);
  const sa = new StylistAuth();
  const navigate = useNavigate();

  const { loading: ReservationsLoading, data: reservationsData, error: reservationsError, refetch: refetchReservations } = useQuery(QUERY_GET_RESERVATION_BLOCKS_FOR_STAFF, {
    client: AuthedGqlSingletonFactory.getInstance(sa.accessToken),
    variables: {
      data: {
        date: fromJSDateToUTCDateTime(selectedDay).startOf('day'),
        includeReservationDetails: true
      }
    }
  });
  const [deleteReservation, { loading, error }] = useMutation(MUTATION_DELETE_RESERVATION_FOR_STAFF, {
    variables: {
      data: {
        reservationId: deleteReservationId
      },
    },
    client: gqlClient,
    fetchPolicy: 'network-only'
  });

  const [createClientReservationComments, { 
    loading: loadingCreateClientReservationComment,
    error:  errorCreateClientReservationComment
  }] = useMutation(MUTATION_CREATE_CLIENT_RESERVATION_COMMENTS, {
    client: AuthedGqlSingletonFactory.getInstance(sa.accessToken),
    fetchPolicy: 'network-only'
  });

  const [fetchClientReservationComments, {
     data: clientReservationComments, 
     loading: loadingClientComments, 
     error: errorClientComments,
     refetch: refetchClientReservationComments
    }] = useLazyQuery(QUERY_GET_CLIENT_RESERVATION_COMMENTS, { 
      client: AuthedGqlSingletonFactory.getInstance(sa.accessToken), 
      fetchPolicy: 'network-only'
    });

  const [deleteReservationComment, { loading: loadingDeleteReservationComment, error: errorDeleteReservationComment }] = useMutation(
    MUTATION_DELETE_CLIENT_RESERVATION_COMMENTS, {
      client: AuthedGqlSingletonFactory.getInstance(sa.accessToken),
      fetchPolicy: 'network-only'
    });

  useEffect(() => {
    if (!sa.isLoggedIn) {
      navigate("/staff/login")
    }
  }, [sa.isLoggedIn]);

  useEffect(() => {
    const uniqueReservations: any[] = [];
    const mergedReservationsMap: {[k: string]: boolean} = {};
    reservationsData && reservationsData.getReservationBlocksForStaff.reservations.forEach((reservation: any) => {
      if (reservation.reservationId === null) {
        uniqueReservations.push(reservation);
      } else {
        if (!mergedReservationsMap[reservation.reservationId]) {
          uniqueReservations.push(reservation);
          mergedReservationsMap[reservation.reservationId] = true;
        }
      }
    });
    setMergedReservationsData(uniqueReservations);
  }, [reservationsData]);
  return (
    <Container>
    <h1>Admin</h1>
      <Segment>
        <Header as='h2' attached='top'>Rezervari</Header>
        <Calendar
          onChange={setSelectedDay}
        ></Calendar>
        <StylistPicker 
          onSelectedStylistId={setSelectedStylistId}
        />

          <List relaxed>
          {mergedReservationsData && mergedReservationsData.map((reservationBlock: IDayTimeBlockWithStaffDetails) => {
            return reservationBlock.booked ? 
              <List.Item>
                <List.Content>
                  <Segment attached="top">
                    <List.Header as='a'>Ora: {standardiseHoursFormat(reservationBlock.interval)}</List.Header>
                    <List.Description as='a'>Clientul: {reservationBlock.client.firstname} {reservationBlock.client.lastname}{" | "}{reservationBlock.client.phonenumber}{" | "}{reservationBlock.client.email}</List.Description>
                    <List.Description as='a'>Tip rezervare: {reservationBlock.reservation && reservationBlock.reservation.types ? reservationBlock.reservation.types.map(rt => rt.reservationType.name).join(' + ') : ''}</List.Description>
                  </Segment>
                  <Segment attached="bottom">
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      <Button.Group widths="2">
                      <Modal
                        trigger={
                          <Button color="orange" size="tiny"  icon labelPosition='left'>
                            Adauga notite
                            <Icon name='sticky note' />
                            </Button>
                        }
                        header="Notite precedente"
                        onClose={() => {
                          setClientReservationNoteText(null);
                          setCommentSaved(false);
                        }
                        }
                        content={
                            <>
                            <Segment attached>
                              <Form>
                                <TextArea 
                                  placeholder='Tell us more'
                                  style={{ minHeight: 100 }}
                                  onChange={(e) => {
                                    setClientReservationNoteText(e.target.value);
                                  }}
                                />
                              </Form>
                            </Segment>
                            <Segment attached textAlign='right'>
                              {commentSaved ? <Label>Salvat ✅</Label> : null}
                              <Button
                                type="submit"
                                color="green"
                                icon='save'
                                loading={loadingCreateClientReservationComment}
                                onClick={
                                  async() => {
                                    setCommentSaved(false);
                                    await createClientReservationComments({
                                      variables: {
                                        data: {
                                          clientId: reservationBlock.client.id,
                                          reservationId: reservationBlock.reservationId,
                                          content: clientReservationNoteText
                                        }
                                      }
                                    });
                                    if (!errorCreateClientReservationComment) {
                                      setCommentSaved(true);
                                    }
                                  }
                                }
                              >
                                Salveaza
                              </Button>
                            </Segment>
                            </>
                          }
                          actions={[
                            { key: 'Inchide', content: 'Inchide', positive: false, onClick: () => {setCommentSaved(false)}},
                          ]}
                          >
                          </Modal>
                          <Modal
                            onOpen={async () => {
                              await fetchClientReservationComments({
                                variables: {
                                  data: {
                                    "first": 10,
                                    "skip": 0,
                                    "clientId": reservationBlock.client.id
                                  }
                                }
                              });
                              console.log(clientReservationComments)
                            }}
                            trigger={
                              <Button color="purple" icon labelPosition='left'>
                                Vezi notite
                                <Icon name='sticky note outline' />
                                {/* <Icon name='sticky note outline' /> */}
                              </Button>
                            }
                            header="Notite precedente"
                            content={
                              <Segment>
                                <List divided>
                                  {
                                  clientReservationComments && clientReservationComments.getClientReservationComments.edges.map((edge: any) => 
                                    <List.Item>
                                      <List>
                                        <List.Item>
                                          <List.Content>
                                            <List.Header>Creat la {new Date(edge.node.createdAt).toLocaleDateString('ro-RO')}</List.Header>
                                            <List.Header>Editat ultima data la {new Date(edge.node.updatedAt).toLocaleDateString('ro-RO')}</List.Header>
                                            <pre>
                                              {edge.node.content}
                                            </pre>
                                          </List.Content>
                                        </List.Item>
                                      </List>
                                      <Button
                                        color="red"
                                        icon="delete"
                                        loading={loadingDeleteReservationComment}
                                        onClick={async () => {
                                          await deleteReservationComment({
                                            variables: {
                                              id: edge.node.id
                                            }
                                          });
                                          await refetchClientReservationComments();
                                        }}
                                      >
                                        Sterge
                                      </Button>
                                    </List.Item>
                                  )}
                                </List>
                              </Segment>
                            }
                            actions={[
                              { key: 'Inchide', content: 'Inchide', positive: false, onClick: () => {console.log('CLICKED Nu')}},
                            ]}
                          >
                        </Modal>
                      </Button.Group>
                    </Grid.Column>
                    <Grid.Column>
                      <Button.Group widths="2">
                        <Button color="blue" size="tiny" icon labelPosition='left'>
                          Modifica
                          <Icon name='edit outline' />
                        </Button>
                        <Modal
                          trigger={<Button color="red" size="tiny" icon labelPosition='left'>
                            Sterge
                            <Icon name='delete calendar' />
                          </Button>}
                          header='Sigur vrei sa stergi aceasta rezervare?'
                          content=''
                          actions={[
                            { key: 'Nu', content: 'Nu', positive: false, onClick: () => {console.log('CLICKED Nu')}},
                            { key: 'Da', content: '⚠️ Da', positive: true, onClick: async () => {
                              console.log(reservationBlock.reservationId);
                              setDeleteReservationId(reservationBlock.reservationId);
                              await deleteReservation({
                                variables: {
                                  data: {
                                    reservationId: reservationBlock.reservationId
                                  }
                                }
                              });
                              refetchReservations();
                            }}
                          ]}
                        />
                      </Button.Group>
                    </Grid.Column>
                  </Grid>
                  </Segment>
                </List.Content>
              </List.Item>
              :                     
              <List.Item>
                <List.Content>
                  <Segment attached="top">
                    <List.Header as='a'>Ora: {standardiseHoursFormat(reservationBlock.interval)}</List.Header>
                  </Segment>
                  <Segment attached>
                    <List.Description as='a'>LIBER</List.Description>
                  </Segment>
                  <Segment attached="bottom">
                    <Button color="green" size="tiny">Creeaza rezervare</Button>
                  </Segment>
                  
                </List.Content>
              </List.Item>
          })}
          </List>
        </Segment>
        <button onClick={() => { sa.logOut(); navigate("/staff/login"); }}>Log out</button>

    </Container>
  );
}

export default StylistViewReservationsPage;