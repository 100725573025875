import { useLazyQuery, useQuery } from '@apollo/client';
import { PresignedPost } from 'aws-sdk/clients/s3';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Form, Input, Label, LabelGroup, Message } from 'semantic-ui-react';
import { gqlClient } from '../../queries/base';
import { QUERY_GET_CLIENT_BY_EMAIL } from '../../queries/Client';
import { IReservationType } from '../../queries/ReservationTypes';
import { isEmail, useDebounce } from '../../utils';
import ClientRegister from '../registerClient/registerClient';

const SEARCH_DEBOUNCE_TIMEOUT = 500;

interface IClientAuthProps {
  onClientIdChange: (uid: string) => void;
  onIsNewClientChange: (uid: boolean) => void;
  onClientEmailChange: (email: string) => void;
  onIsActiveClientChange: (isActive: boolean) => void;
  onClientEmailValidChange: (valid: boolean) => void;
}

const ClientAuth = (props: IClientAuthProps) => {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [emailIsValid, setEmailIsValid] = useState<boolean | null>(null);
  const [isActiveClient, setIsActiveClient] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("");

  const debouncedUserEmail = useDebounce(userEmail, SEARCH_DEBOUNCE_TIMEOUT);


    const [clientId, setClientId] = useState<string | null>(null);
    const [
      fetchUserAccount,
      {
        loading: fetchUserAccountLoading,
        data: userAccountReq,
        error: fetchUserAccountError
      }
    ] = useLazyQuery(QUERY_GET_CLIENT_BY_EMAIL, {
        client: gqlClient,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
      props.onClientEmailChange(debouncedUserEmail || "");
      if (debouncedUserEmail) {
        const isValid = isEmail(debouncedUserEmail);
        if (isValid) {
          setEmailIsValid(true);
          fetchUserAccount({
            variables: {
              email: debouncedUserEmail
            }
          });
          props.onClientEmailValidChange(true);
        } else {
          props.onClientEmailValidChange(false);
          setEmailIsValid(debouncedUserEmail === "" ? null : false);
        }
      }
    }, [debouncedUserEmail]);
    useEffect(() => {
      if (userAccountReq && userAccountReq.getClient) {
        const userAccount = userAccountReq.getClient;
        if (userAccount && !fetchUserAccountLoading) {
          setIsActiveClient(userAccount.isActive);
          setUserName(userAccount.firstname);
          props.onIsNewClientChange(false);
          props.onIsActiveClientChange(userAccount.isActive);
          props.onClientIdChange(userAccount.id);
        }
      } else {
        if (!fetchUserAccountLoading) {
          setIsActiveClient(false);
          props.onIsNewClientChange(true);
        }
      }
      // if (userAccount.)
    }, [userAccountReq]);
      return (
        <Form attached>
          <Form.Field>
          {
            (emailIsValid === false) 
            && <Label basic color='red' pointing='below'>
              Email invalid
            </Label>
          }
            Emailul tau:
            <Input
              type="email"
              loading={fetchUserAccountLoading}
              onChange={(e) => { 
                setUserEmail(e.target.value.trim())
              }}
            />
          </Form.Field>
          {
            isActiveClient && 
              <Message>👋 Bine ai revenit, {userName}!</Message>

          }
        </Form>
      );
};

export default ClientAuth;

