import { gql } from "@apollo/client";

export const MUTATION_CREATE_RESERVATION = gql`
  mutation CreateReservation($data: CreateReservationInput!) {
    createReservation(data: $data) {
      reservationActive
    }
  }
`;

export const QUERY_GET_RESERVATIONS = gql`
  query GetReservations($data: GetReservationsInput!) {
    getReservations(data: $data) {
      id
    }
  }
`;

export const MUTATION_DELETE_RESERVATION_FOR_CLIENT = gql`
  mutation DeleteReservation($data: DeleteReservationInput!) {
    deleteReservation(data: $data) {
      success
    }
  }
`;

export const MUTATION_DELETE_RESERVATION_FOR_STAFF = gql`
  mutation DeleteReservationForStaff($data: DeleteReservationForStaffInput!) {
    deleteReservationForStaff(data: $data) {
      success
    }
  }
`;
