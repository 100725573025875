import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Form, FormCheckbox, Label, LabelGroup, Radio, Segment } from 'semantic-ui-react';
import { gqlClient } from '../../queries/base';
import { IReservationType, QUERY_GET_RESERVATION_TYPES } from '../../queries/ReservationTypes';
import './style.css';


export type TObjectWithId = { id: string; [k: string]: any };

interface IServicesListProps {
  onTotalPriceChange: (totalPrice: number) => void;
  onTotalDurationMinsChange: (totalDurationMins: number) => void;
  onMainServiceChange: (mainService: IReservationType | null) => void;
  onAddonServicesChange: (addonServices: IReservationType[]) => void;
}

function ServicesList(props: IServicesListProps) {
    const { loading: reservationBlocksLoading, data, error } = useQuery(QUERY_GET_RESERVATION_TYPES, {
        client: gqlClient
    })
    const [selectedMainService, setSeletecedMainService] = useState<IReservationType | null>(null);
    const [selectedAddons, setSelectedAddons] = useState<IReservationType[]>([]);
    useEffect(() => {
      const mainPrice = selectedMainService ? selectedMainService.price : 0;
      const totalToPay = mainPrice + selectedAddons.reduce((prev, cur) => ({ price: prev.price + cur.price }), { price: 0 }).price;

      const mainDuration = selectedMainService ? selectedMainService.durationMins : 0;
      const totalDuration = mainDuration + selectedAddons.reduce((prev, cur) => ({ durationMins: prev.durationMins + cur.durationMins }), { durationMins: 0 }).durationMins;

      props.onTotalDurationMinsChange(totalDuration);
      props.onTotalPriceChange(totalToPay);
      props.onMainServiceChange(selectedMainService);
      props.onAddonServicesChange(selectedAddons)
    }, [selectedMainService, selectedAddons]);
  return (
    <Form>
      <Form.Field>
        Selecteaza un serviciu:
      </Form.Field>
      {
        reservationBlocksLoading 
        ? "Loading..."
        : 
        data && data.getReservationTypes.map((reservationType: any, index: number) => {
            return (
              <Form.Field key={index}>
                {
                  <>
                    {reservationType.subtype === "MAIN"
                    ? <Radio
                        label={reservationType.name}
                        name='radioGroup'
                        value={index}
                        checked={Boolean(selectedMainService && selectedMainService.id === reservationType.id)}
                        onChange={(e, { value }) => setSeletecedMainService(data.getReservationTypes[index])}
                      />
                    : <FormCheckbox 
                      label={reservationType.name}
                        value={index}
                        checked={(function () {
                          const a = selectedAddons.reduce((prev, cur) => {
                            return prev || (cur.id === data.getReservationTypes[index].id);
                          }, false);
                          return a;
                        }())}
                        onChange={(e, { value }) => {
                          const selectedType = data.getReservationTypes[Number(value)];
                          let wasCheckedBefore = false;
                          const selectedAddonsClone = selectedAddons.filter(val => {
                            if (val.id === selectedType.id) {
                              wasCheckedBefore = true;
                              return false;
                            }
                            return true;
                          });
                          if (!wasCheckedBefore) {
                            selectedAddonsClone.push(selectedType);
                          }
                          setSelectedAddons(selectedAddonsClone)
                      }}
                    />}
                    <LabelGroup>
                      {
                        reservationType.description && 
                        <LabelGroup>
                            <Label basic size='large'>
                              {reservationType.description}
                            </Label>
                        </LabelGroup>
                      } 
                      {/* <Label>
                        ⏱️ {reservationType.durationMins} minute{" "}
                      </Label> */}
                      <Label>
                        {reservationType.price} RON
                      </Label>
                    </LabelGroup>
                      

                  </>
                }
              </Form.Field>
            );
        })}

      </Form>
  );
}

export default ServicesList;