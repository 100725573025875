import { gql } from '@apollo/client';

export const QUERY_GET_STYLIST_DAYS = gql`
query GetStylistDays($data: GetStylistDaysInput!) {
  getStylistDays(data: $data) {
    date
    id
    stylistId
  }
}
`;

export const QUERY_GET_ALL_STYLISTS = gql`
query GetAllStylists {
  getAllStylists {
    id
    firstname
    lastname
  }
}
`;

export const MUTATION_LOGIN_STYLIST = gql`
mutation LoginStylist($data: LoginInput!) {
    login(data: $data) {
      accessToken
      refreshToken
    }
}
`;