import React from 'react';
import logo_banner from '../../logo_banner.jpg';
import './style.css';

const Header = () => {
    return (
        <header>
            <img src={logo_banner} />
            {/* <h1>Frizeria cu dichis</h1> */}
        </header>
    );
}

export default Header;