import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Form } from 'semantic-ui-react';
import { gqlClient } from '../../queries/base';
import { MUTATION_LOGIN_STYLIST } from '../../queries/Stylist';
import { StylistAuth } from '../../services/stylistAuth';

const StaffLogin = () => {
    const sa = new StylistAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
  

    const [loginStylist, { loading, error: loginStylistError }] = useMutation(MUTATION_LOGIN_STYLIST, {
      variables: {
        data: {
          email: email,
          password: password,
        },
      },
      client: gqlClient,
      fetchPolicy: 'network-only'
    });
  
    return (
        <Form>
            <Form.Input label='Email' type='text' onChange={(e) => { e.preventDefault(); setEmail(e.target.value)}}/>
            <Form.Input label='Parola' type='password' onChange={(e) => { e.preventDefault(); setPassword(e.target.value)}} />
            <Form.Button
             onClick={async (e) => { e.preventDefault(); 
                const res = await loginStylist();
                sa.accessToken = res.data.login.accessToken;
                sa.refreshToken = res.data.login.refreshToken;
                navigate("/staff/reservations")
            }}
            >
                Login
            </Form.Button>
        </Form>
    );
};

export default StaffLogin;