import { gql } from "@apollo/client";

export interface IReservationType {
  id: string;
  name: string;
  durationMins: number;
  price: number;
  subtype: "Main" | "Addon";
  description: string;
}

export const QUERY_GET_RESERVATION_TYPES = gql`
query ReservationTypes {
    getReservationTypes {
      id
      name
      durationMins
      price
      subtype
      description
    }
  }
`;