import { ApolloClient, InMemoryCache } from "@apollo/client";
import { DocumentNode } from "graphql";
// import ApolloClient, { createNetworkInterface } from 'apollo-client'

export async function makeGQLQuery(query: DocumentNode, params: any) {
    const fetchRes = await fetch(`${process.env.REACT_APP_BE_HOST}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: params, // this refers to SKU
        })
      });
    const fetchResAsJSON = fetchRes.json();
    return fetchResAsJSON;
}

export const gqlClient = new ApolloClient({
    uri: `${process.env.REACT_APP_BE_HOST}/graphql`,
    cache: new InMemoryCache()
})

export default class AuthedGqlSingletonFactory {
  static _instance: ApolloClient<{}> | null = null;
  _state = {};
  static getInstance(token: string): ApolloClient<{}> {
      if (AuthedGqlSingletonFactory._instance == null) {
          AuthedGqlSingletonFactory._instance = new ApolloClient({
            uri: `${process.env.REACT_APP_BE_HOST}/graphql`,
            cache: new InMemoryCache(),
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
      }
      return this._instance as ApolloClient<{}>;
  }
  // static get state() { 
  //     return Data.inst._state; 
  // }
  // static set state(state) { 
  //     Data.inst._state = state; 
  // }
  // static setState(state) { 
  //     Data.inst._state = {...Data.inst._state, ...state} 
  // }
}