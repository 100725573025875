import { useMutation } from '@apollo/client';
import React from 'react';
import { useEffect, useState } from "react";
import { redirect } from 'react-router';
import { Button, Container, Grid, Header, Segment, Select } from 'semantic-ui-react';
import StaffLogin from '../../../components/staffLogin/staffLogin';
import { gqlClient } from '../../../queries/base';
import { StylistAuth } from '../../../services/stylistAuth';


function StylistLoginPage() {
  const sa = new StylistAuth();
  
  return (
    <Container>
      <Segment>
        <Header>Login stilist</Header>
        <StaffLogin />
      </Segment>
    </Container>
  );
}

export default StylistLoginPage;