import { gql } from "@apollo/client";

export const QUERY_GET_CLIENT_RESERVATION_COMMENTS = gql`
query GetClientReservationComments(
    $data:GetClientReservationCommentInput!
) {
    getClientReservationComments(
        data:$data
    ) {
        edges{
            node{
                id
                content
                createdAt
                updatedAt
            } 
        }
    }
}
`;

export const MUTATION_CREATE_CLIENT_RESERVATION_COMMENTS = gql`
mutation CreateClientReservationComment(
    $data: CreateClientReservationCommentInput!
) {
    createClientReservationComment(
        data:$data
    ) {
        success
    }
}
`;

export const MUTATION_DELETE_CLIENT_RESERVATION_COMMENTS = gql`
mutation DeleteClientReservationComment(
    $id: String!
) {
    deleteClientReservationComment(
        id:$id
    ) {
        success
    }
}
`;