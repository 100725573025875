import React, { useEffect, useState } from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
import PageHeader from '../../components/header';

import { Routes, Route, useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MUTATION_DELETE_RESERVATION_FOR_CLIENT } from '../../queries/Reservations';
import { gqlClient } from '../../queries/base';

function CancelReservationsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const cancelToken = searchParams.get("cancelToken")
    const reservationId = searchParams.get("reservationId")

    const [deleteReservation, { loading, error }] = useMutation(MUTATION_DELETE_RESERVATION_FOR_CLIENT, {
      variables: {
        data: {
          reservationId,
          cancelationToken: cancelToken
        },
      },
      client: gqlClient,
      fetchPolicy: 'network-only'
    }
    );
    const [cancelationSuccessful, setCancelationSuccessful] = useState<boolean>(false);
    return (
      <Container>
      <PageHeader />
      <Segment>
        {
          !cancelationSuccessful ?
          <Button
            onClick={async (e: any) => {
              e.preventDefault();
              const deleteRes = await deleteReservation();
              setCancelationSuccessful(deleteRes 
                && deleteRes.data 
                && deleteRes.data.deleteReservation 
                && deleteRes.data.deleteReservation.success
              );
            }}
          >
            Anuleaza rezervarea
          </Button>
          : <Segment>
            Rezervare anulata ✅ 
            <br></br>
            Te mai asteptam!
          </Segment>
        }
      </Segment>
      </Container>
    )
}

export default CancelReservationsPage;