import { useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Label, LabelGroup, Popup, Segment } from 'semantic-ui-react';
import { standardiseHoursFormat } from '../../queries/adapters';
import { gqlClient } from '../../queries/base';
import { IReservationBlock, QUERY_GET_RESERVATION_BLOCKS } from '../../queries/ReservationBlocks';
import { THrMin } from '../../types';
import { Range } from '../../types';
import { fromJSDateToUTCDateTime } from '../../utils';
import './dayTimeblocks.css';

interface ITimeBlockWithIndex extends IDayTimeBlock{
    index: number;
}

export interface IDayTimeBlock {
    hr?: Range<0,23>;
    min?: Range<0,59>;
}

export interface IAdminConfigurableConfig {
    scheduleConfig: {
        activeScheduleType: "consecutive" | "days of week"
    },

}

function _getHrsMinsFromHrMinString(hrMinString: THrMin): IDayTimeBlock {
    const [hr, min] = hrMinString.split(':').map(Number);
    return { hr, min } as IDayTimeBlock;
}

function handleDayTimeBlockButtonClick(
    timeBlock: ITimeBlockWithIndex,
    onChangeDayTimeBlock: (rdtb: IDayTimeBlock) => void,
    setSelectedTimeBlock: (tb: ITimeBlockWithIndex) => void
) {
    return (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
        onChangeDayTimeBlock(timeBlock);
        setSelectedTimeBlock(timeBlock);
    }
}

function hourIsInThePast(allIntervals: IReservationBlock[], toTestIntervalIndex: number, calendarSelectedDay: Date): boolean {
    const testTimeBlockHrMin = _getHrsMinsFromHrMinString(allIntervals[toTestIntervalIndex].interval);
    const toTestTimeBlock = DateTime.fromJSDate(calendarSelectedDay).set({ hour: testTimeBlockHrMin.hr, minute: testTimeBlockHrMin.min });
    const res = toTestTimeBlock <= DateTime.fromJSDate(new Date());
    return res;
}

function nextIntervalsAreFree(
    newReservationDuration: number,
    allIntervals: IReservationBlock[],
    toTestIntervalIndex: number
) {

    const testTimeBlockHrMin = _getHrsMinsFromHrMinString(allIntervals[toTestIntervalIndex].interval);
    const toTestTimeBlock = DateTime.fromJSDate(new Date()).set({ hour: testTimeBlockHrMin.hr, minute: testTimeBlockHrMin.min });
    let intervalTooSmall = false;
    let dateIterator = DateTime.fromJSDate(new Date());
    let i = toTestIntervalIndex;
    let freeTimeMins = 0;
    for (; i < allIntervals.length - 1; i++) {
        if (allIntervals[i].booked) {
            return false;
        }
        const curHrMin = _getHrsMinsFromHrMinString(allIntervals[i+1].interval);
        dateIterator = dateIterator.set({ hour: curHrMin.hr, minute: curHrMin.min });
        freeTimeMins = Interval.fromDateTimes(toTestTimeBlock, dateIterator).length('minutes');
        if (freeTimeMins >= newReservationDuration) {
            return true;
        }
    }
    return false;
}

function DayTimeBlock(props: {
    stylistId: string;
    day: Date;
    reservationDurationMins: number;
    onChangeDayTimeBlock: (rdtb: IDayTimeBlock) => void;
    selectDisabled: { reason: string } | false;
}) {

    const { loading: reservationBlocksLoading, data, error } = useQuery(QUERY_GET_RESERVATION_BLOCKS, {
        variables: {
            data: {
                stylistId: props.stylistId,
                date: fromJSDateToUTCDateTime(props.day)
            }
        },
        client: gqlClient
    });

    const [selectedTimeBlock, setSelectedTimeBlock] = useState<ITimeBlockWithIndex | null>(null);

    useEffect(() => {
        setSelectedTimeBlock(null);
    }, [props.day]);
    return (
        <>
            {
                !data
                ? <Segment><Label size='large'>Frizeria este inchisa in aceasta zi </Label></Segment>
                : <div
                    className="reservationsBlockDisabledWrapper"
                >
                {props.selectDisabled && <div className="reservationsBlockDisabledOverlay">
                    <Label size='large'>{props.selectDisabled.reason} </Label>
                </div>}
                {
                    reservationBlocksLoading
                    ? <div>"Loading..."</div>
                    : <ul className="reservationsBlockWrapper">{
                        data && data.getReservationBlocks.reservations.map((reservationBlock: any, index: number) => {

                            const isFreeInterval = nextIntervalsAreFree(props.reservationDurationMins, data.getReservationBlocks.reservations, index);
                            const isInThePast = hourIsInThePast(data.getReservationBlocks.reservations, index, props.day);
                            let bookedClassName = (isInThePast || reservationBlock.booked || !isFreeInterval) ? "reservationBlock-booked" : "reservationBlock-free"
                            if (selectedTimeBlock && (selectedTimeBlock.index === index)) {
                                bookedClassName += " reservationBlock-active";
                            }
                            return (
                                <li 
                                    key={index}
                                    className={`reservationBlock ${bookedClassName}`}
                                    onClick={isFreeInterval ? handleDayTimeBlockButtonClick({
                                        index,
                                        ..._getHrsMinsFromHrMinString(reservationBlock.interval),
                                    }, 
                                    props.onChangeDayTimeBlock,
                                    setSelectedTimeBlock)
                                : (e) => {e.preventDefault();}}
                                >
                                    {standardiseHoursFormat(reservationBlock.interval)}
                                </li>
                            );
                        })}
                    </ul>
                    }
            </div>
            }
        </>
    );
}

export default DayTimeBlock;