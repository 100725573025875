import React, { useEffect, useState } from 'react';
import DayTimeBlock, { IDayTimeBlock } from '../dayTimeBlocks/dayTimeBlocks';
import { DateTime } from 'luxon';
import { QUERY_GET_STYLIST_DAYS } from '../../queries/Stylist';
import { useQuery } from '@apollo/client';
import { gqlClient } from '../../queries/base';
import { Range } from "../../types";
import Calendar from '../calendar';
import { fromJSDateToUTCDateTime, reduceDateOffset } from '../../utils';

export interface IStylistDay { date: string, stylistId: string, id: string };

const dayIsWithin30DaysInFuture = (startOfDayToTest: DateTime, startOfToday: DateTime, todayPlus30Days: DateTime): boolean => {
    return startOfDayToTest >= startOfToday && startOfDayToTest <= todayPlus30Days;
}

export interface IReservationObj extends IDayTimeBlock {
    dayMonthYear: Date;
    hr?: Range<0,23>;
    min?: Range<0,59>;
}

interface IReservationSelectorProps {
    stylistId: string;
    reservationDurationMins: number;
    onChangeReservationDate: (reservationObj: IReservationObj) => void;
    timeBlockSelectDisabled: { reason: string } | false;
}

const reservationSelector = function(props: IReservationSelectorProps) {
    const [reservationDayTimeBlock, setReservationDayTimeBlock] = useState<IDayTimeBlock | null>(null);
    const [calendarDataMap, setCalendarDataMap] = useState<{ [k: string ]: boolean}>({});
    const startOfToday = DateTime.fromJSDate(new Date()).startOf('day');
    const [calendarValue, setCalendarValue] = useState<Date>(startOfToday.toJSDate());

    const todayPlus30Days = startOfToday.plus({ day: 30 });
    
    const { loading: calendarLoading, data: calendarData, error: calendarLoadError } = useQuery(QUERY_GET_STYLIST_DAYS, {
        client: gqlClient,
        variables: { 
            data: {
                startDate: startOfToday.minus({ day: 31 }),
                endDate: startOfToday.plus({ day: 31 }),
                stylistId: props.stylistId
            }
        },
    });

    useEffect(() => {
        const tmpCalendarDataMap: { [k: string ]: boolean} = {};
        calendarData && calendarData.getStylistDays.forEach((sd: IStylistDay) => {
            tmpCalendarDataMap[DateTime.fromISO(sd.date, { zone: 'utc' }).toString()] = true;
        });
        setCalendarDataMap(tmpCalendarDataMap);
    }, [calendarData]);

    useEffect(() => {
        props.onChangeReservationDate({
            dayMonthYear: calendarValue,
            hr: reservationDayTimeBlock ? reservationDayTimeBlock.hr: undefined,
            min: reservationDayTimeBlock ? reservationDayTimeBlock.min: undefined
        })
    }, [reservationDayTimeBlock, calendarValue]);

    return (
        <>
            <Calendar
                onChange={setCalendarValue} 
                value={calendarValue}
                tileDisabled={
                    ({
                        activeStartDate,
                        date,
                        view 
                    }: { activeStartDate: any, date: Date, view: any}) => {
                        const calendarDay = fromJSDateToUTCDateTime(date).startOf('day');
                        return !(dayIsWithin30DaysInFuture(calendarDay, startOfToday, todayPlus30Days))
                    || !calendarDataMap[calendarDay.toString()]
                    }
                }
            />
            <DayTimeBlock 
                day={calendarValue} 
                stylistId={props.stylistId} 
                reservationDurationMins={props.reservationDurationMins}
                onChangeDayTimeBlock={setReservationDayTimeBlock}
                selectDisabled={props.timeBlockSelectDisabled}
            />
        </>
    );
}

export default reservationSelector;