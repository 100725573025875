import { gql } from '@apollo/client';
import { THrMin } from '../types';

export interface IReservationBlock {
  booked: boolean;
  interval: THrMin;
}

export const QUERY_GET_RESERVATION_BLOCKS = gql`
query ReservationBlocks($data: GetReservationInput!) {
  getReservationBlocks(data: $data) {
    reservations {
      booked
      interval
    }
  }
}
`;

export const QUERY_GET_RESERVATION_BLOCKS_FOR_STAFF = gql`
query ReservationBlocksForStaff($data: GetReservationsForStaffInput!) {
  getReservationBlocksForStaff(data: $data) {
    reservations {
      reservationId
      booked
      interval
      reservation {
        id
        types {
          reservationType {
            name
            price
          }
        }
      }
      stylist {
        id
        firstname
        lastname
      }
      client {
        id
        firstname
        lastname
        email
        phonenumber
      }
    }
  }
}
`;