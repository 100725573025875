import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import 'react-calendar/dist/Calendar.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ReservationsPage from './pages/reservationsPage/reservationsPage';
import StylistLoginPage from './pages/staff/stylistLoginPage/stylistLoginPage';
import StylistViewReservationsPage from './pages/staff/stylistViewReservationsPage/stylistViewReservationsPage';
import ConfirmAccountPage from './pages/confirmAccountPage/confirmAccountPage';
import CancelReservationsPage from './pages/cancelReservationPage/cancelReservationPage';
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: "https://57157e58d31c4bb59ea7d96c74f9d424@o4505135729082368.ingest.sentry.io/4505135738257408",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ReservationsPage />,
  },
  {
    path: "/confirm",
    element: <ConfirmAccountPage />
  },
  {
    path: "/reservations/cancel",
    element: <CancelReservationsPage />
  },
  {
    path: "/staff/login",
    element: <StylistLoginPage />
  },
  {
    path: "/staff/reservations",
    element: <StylistViewReservationsPage />
  }
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
