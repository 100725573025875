import React from 'react';
import './style.css';

const Map = function() {
    return (
        <div className="map-wrapper">
          <div id="google-maps-canvas" className="map-inner-wrapper">
            <iframe className="map-iframe-wrapper" frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=Calea+Moșilor+264,+Bucharest,+Romania&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
          </div>
        </div>
    );
}
export default Map;