import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useEffect, useState } from "react";
import { gqlClient, makeGQLQuery } from "../../queries/base";
import { MUTATION_CREATE_RESERVATION } from "../../queries/Reservations";
import { Button, Container, Grid, Header, Label, LabelGroup, Popup, Segment, Select } from 'semantic-ui-react';
import ServicesList from '../../components/servicesList';
import ReservationSelector, { IReservationObj } from '../../components/reservationSelector';
import { QUERY_GET_ALL_STYLISTS } from '../../queries/Stylist';
import PageHeader from '../../components/header';
import Map from "../../components/map";
import { IReservationType } from '../../queries/ReservationTypes';
import ClientAuth from '../../components/clientAuth';
import StylistPicker from '../../components/stylistPicker/stylistPicker';
import { fromJSDateToUTCDateTime } from '../../utils';
import ClientRegister from '../../components/registerClient/registerClient';

interface IStylist {
  id: string;
  firstname: string;
  lastname: string;
}

function buildReservationPayload(
  clientId: string | null,
  selectedReservationObj: IReservationObj | null,
  selectedAddons: IReservationType[],
  selectedMainService: IReservationType | null,
  selectedStylistId: string | null
) {
  return {
    clientId,
    dayMonthYear: selectedReservationObj ? fromJSDateToUTCDateTime(selectedReservationObj.dayMonthYear) : undefined,
    reservationTypeIds: selectedAddons.concat(selectedMainService ? [selectedMainService] : []).map(r => r.id),
    startHour: selectedReservationObj ? selectedReservationObj.hr : undefined,
    startMinute: selectedReservationObj ? selectedReservationObj.min : undefined,
    stylistId: selectedStylistId
  };
}

/**
 * This is used in other components as well. Client esp. needs to be passed as a param and *not read from state*
 */
function handleSendReservationButton(
  selectedReservationObj: IReservationObj | null,
  selectedAddons: IReservationType[],
  selectedMainService: IReservationType | null,
  selectedStylistId: string | null,
  createReservationMutation: (data: any) => { data: { createReservation: { reservationActive: boolean }}},
  setReservationCreated: (val: boolean) => void
) {
  return (clientId: string | null) =>
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const res = await createReservationMutation({
      variables: {
        data: buildReservationPayload(
          clientId,
          selectedReservationObj,
          selectedAddons,
          selectedMainService,
          selectedStylistId
        )
      },
    });
    if (res && res.data && res.data.createReservation && res.data.createReservation.reservationActive ) {
      setReservationCreated(true);
    }
  }
}

function ReservationsPage() {
  const { loading: reservationBlocksLoading, data: allStylistsData, error } = useQuery(QUERY_GET_ALL_STYLISTS, {
      client: gqlClient
  });
  const [selectedMainService, setSeletecedMainService] = useState<IReservationType | null>(null);
  const [resdervationCreated, setReservationCreated] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [selectedAddons, setSelectedAddons] = useState<IReservationType[]>([]);
  const [selectedReservationObj, setSelectedReservationObj] = useState<IReservationObj | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isNewClient, setIsNewClient] = useState<boolean | null>(null);
  const [isActiveClient, setIsActiveClient] = useState<boolean | null>(null);
  const [clientEmail, setClientEmail] = useState<string | null>(null);
  const [clientEmailValid, setClientEmailValid] = useState<boolean>(false);
  const [totalDurationMins, setTotalDurationMins] = useState<number>(0);

  const [selectedStylistId, setSelectedStylistId] = useState<string | null>(null);
  const [reservationFieldsComplete, setReservationFieldsComplete] = useState<boolean>(false);

  const [createReservation, { loading: createReservationLoading, error: createReservationError }] = useMutation(MUTATION_CREATE_RESERVATION, {
    client: gqlClient,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const valid = Boolean((selectedReservationObj ? selectedReservationObj.dayMonthYear : undefined) 
      && (selectedAddons.concat(selectedMainService ? [selectedMainService] : []).map(r => r.id))
      && typeof (selectedReservationObj ? selectedReservationObj.hr : undefined) != 'undefined'
      && typeof (selectedReservationObj ? selectedReservationObj.min : undefined) != 'undefined'
      && (selectedStylistId)
      && (clientEmail)
    );
    if (valid) {
      setReservationFieldsComplete(true);
    } else {
      setReservationFieldsComplete(false);
    }
  }, [
    selectedMainService,
    selectedAddons,
    selectedReservationObj,
    selectedStylistId,
    clientEmail,
    isActiveClient
  ]);

    return (
      <Container>
      <PageHeader />
      <Segment>
        <Grid relaxed={false} stackable centered>
        <Grid.Row>
        <Header as='h2' floated='right'>
          Adresa
        </Header>
        </Grid.Row>
        <Grid.Row centered>
        <Segment attached="top">
              Calea Mosilor nr. 264, Sector 2, Bucuresti
            </Segment>
            <Map />
        </Grid.Row>
        </Grid>
      </Segment>
        <Segment>
          <Grid columns={3}  stackable textAlign='center'>
            <Grid.Row>
              <Grid.Column>
                <Header as='h2' attached='top'>
                  1. Selecteaza un serviciu
                </Header>
                <Segment attached textAlign='left'>
                  <ServicesList
                    onTotalPriceChange={setTotalPrice}
                    onTotalDurationMinsChange={setTotalDurationMins}
                    onMainServiceChange={setSeletecedMainService}
                    onAddonServicesChange={setSelectedAddons}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Header as='h2' attached='top'>
                  2. Alege frizerul si ora prorgamarii
                </Header>
                <Segment attached>
                  <StylistPicker 
                    onSelectedStylistId={setSelectedStylistId}
                  />
                  { selectedStylistId && 
                    <ReservationSelector 
                      stylistId={selectedStylistId} 
                      reservationDurationMins={totalDurationMins}
                      onChangeReservationDate={setSelectedReservationObj}
                      timeBlockSelectDisabled={
                        (!selectedMainService && !selectedAddons.length) 
                        ? { reason: "Trebuie sa selectezi un serviciu inainte de a alege ora" }
                        : false
                      }
                    /> 
                  }
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Header as='h2' attached='top'>
                    3. Datele tale de contact
                </Header>
                <Segment attached>
                  <ClientAuth
                    onIsActiveClientChange={setIsActiveClient}
                    onIsNewClientChange={setIsNewClient}
                    onClientIdChange={setClientId}
                    onClientEmailChange={(v) => {
                      setClientEmail(v);
                    }}
                    onClientEmailValidChange={setClientEmailValid}
                  />
                </Segment>

              {
                (isNewClient !== null && clientEmail !== "" && clientEmailValid) &&
                    isNewClient 
                    ?
                    <>
                      <Header as='h3' attached='top'>
                          3.1 Inregistrare cont nou
                      </Header>
                      <Segment attached>
                        <ClientRegister 
                          email={clientEmail}
                          reservation={{ 
                            shouldCreate: reservationFieldsComplete, 
                            handler: handleSendReservationButton(
                              selectedReservationObj,
                              selectedAddons,
                              selectedMainService,
                              selectedStylistId,
                              // @ts-ignore
                              createReservation,
                              setReservationCreated
                            )
                          }}
                        />
                      </Segment>
                    </>
                    : <Segment attached>
                      {resdervationCreated
                        ?
                        <LabelGroup>
                          <Label size='large'>Rezervare creata. In caz ca vrei sa anulezi, ti-am trimis un email cu un link de anulare.</Label>
                          <Label size='large'>In caz ca nu gasesti emailul, verifica si in Spam.</Label>
                        </LabelGroup>
                        : 
                        <>
                        {/* {
                        
                        } */}
                        {
                          // there's a problem / the button should be disabled
                          isActiveClient !== null && !Boolean(isActiveClient)
                          ? <LabelGroup>{
                              isActiveClient 
                              ? <Label size='large' color='red'>
                                  Asigura-te ca ai selectat un serviciu, un frizer si o ora.
                                </Label>
                              : <Label size='large' color='orange'>
                                  Cont inactiv. Pentru a-l activa, asigura-te ca ai dat click pe linkul din mailul de activare pe care l-ai primit.
                                </Label>
                              
                            }</LabelGroup>
                          : !reservationFieldsComplete 
                          && <LabelGroup>
                            <Label size='large'>
                              Asigura-te ca ai selectat un serviciu, un frizer si o ora si ca ti-ai introdus adresa de email.
                             </Label>
                          </LabelGroup>
                          }
                          <Button
                            disabled={!reservationFieldsComplete || !isActiveClient}
                            onClick={
                              handleSendReservationButton(
                                selectedReservationObj,
                                selectedAddons,
                                selectedMainService,
                                selectedStylistId,
                                // @ts-ignore
                                createReservation,
                                setReservationCreated
                              )(clientId)}>Creeaza rezervare</Button>
                        </>
                        // <Popup
                        //   content={isActiveClient ? 'Asigura-te ca ai selectat un serviciu, un frizer si o ora.' : 'Cont inactiv. Pentru a-l activta, asigura-te ca ai dat click pe linkul din mailul de activare pe care l-ai primit.' }
                        //   disabled={reservationFieldsComplete && Boolean(isActiveClient)}
                        //   on='hover'
                        //   position='top center'
                        //   trigger={
                        //     <div>
                        //     </div>
                        //   }
                        // />
                      }
                    </Segment>
              }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    )
}

export default ReservationsPage;