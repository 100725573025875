import { useEffect } from "react";
import { useNavigate } from "react-router";

export class StylistAuth {
    get accessToken() {
        return localStorage.getItem("accessToken") || "";
    }

    set accessToken(at: string) {
        localStorage.setItem("accessToken", at);
    }

    get refreshToken() {
        return localStorage.getItem("refreshToken") || "";
    }

    set refreshToken(rt: string) {
        localStorage.setItem("refreshToken", rt);
    }

    get isLoggedIn() {
        return Boolean(this.accessToken);
    }

    set isLoggedIn(val: boolean) {
        if (val === false) {
            this.accessToken = "";
            this.refreshToken = "";
        }
    }

    public logOut() {
        this.isLoggedIn = false;
        this.accessToken = "";
        this.refreshToken = "";
    }

    constructor() {}
}
