import { gql } from "@apollo/client";


export const QUERY_GET_CLIENT_BY_EMAIL = gql`
    query Client($email: String!) {
        getClient(email: $email) {
            createdAt
            email
            firstname
            id
            isActive
            lastname
            updatedAt
        }
    }
`;

export const MUTATION_CREATE_CLIENT = gql`
    mutation ClientSignup($data: ClientSignupInput!) {
        clientSignup(data: $data) {
            id
            newUser
        }
    }
`;

export const MUTATION_CONFIRM_CLIENT_SIGNUP = gql`
  mutation ConfirmClientSignup($data: ConfirmClientSignupInput!) {
    confirmClientSignup(data: $data) {
      success
      clientWasPreviouslyActive
      cancelledReservations {
        startHrMinDate
      }
      activatedReservations {
        startHrMinDate
      }
    }
  }
`;
